<template>
    <div>
        <v-dialog v-model="dialogEdit" max-width="800px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Редактирование блока</span>
                </v-card-title>
                <v-card-text>
                    <template
                        v-if="editedItem.type == 1 || editedItem.type == 2 || editedItem.type == 11"
                    >
                        <v-text-field
                            label="Текст"
                            v-model="editedItem.data.value"
                            @input="valueChanged()"
                        ></v-text-field>
                    </template>
                    <template
                        v-if="editedItem.type == 3 || editedItem.type == 12"
                    >
                        <editor
                            :editor="editor"
                            v-model="editedItem.data.value"
                            :config="editorConfig"
                            :init="{setup: (editor) => {
                                editor.on('change input', () => {
                                    valueChangedTiny(editor.getContent());
                                });
                            }}"
                        />
                    </template>
                    <template
                        v-if="editedItem.type == 4"
                    >
                        <v-text-field
                            label="Текст"
                            v-model="editedItem.data.value.text"
                            @input="valueChanged()"
                        ></v-text-field>
                        <v-text-field
                            label="Ссылка"
                            v-model="editedItem.data.value.link"
                            @input="valueChanged()"
                        ></v-text-field>
                        <v-select
                            v-model="editedItem.data.value.align"
                            :items="alignValues"
                            label="Выравнивание"
                            item-text="title"
                            item-value="id"
                            @input="valueChanged()"
                        ></v-select>
                    </template>
                    <template
                        v-if="editedItem.type == 5"
                    >
                        <v-file-input
                            label="Загрузка файла"
                            v-model="editedItem.data.newValue"
                            @input="valueChanged()"
                            @change="valueChanged()"
                        ></v-file-input>
                        <div v-if="editedItem.id > 0">
                            <v-img
                                max-height="150"
                                max-width="250"
                                :src="editedItem.data.value"
                            ></v-img>
                            <label>{{ editedItem.data.name }}</label>
                        </div>
                    </template>
                    <template
                        v-if="editedItem.type == 6"
                    >
                        <v-file-input
                            label="Загрузка файла"
                            v-model="editedItem.data.newValue"
                            @input="valueChanged()"
                            @change="valueChanged()"
                            accept="video/mp4"
                        />
                        <div v-if="editedItem.id > 0 && false">
                            <video :src="editedItem.data.value" controls />
                            <label>{{ editedItem.data.name }}</label>
                        </div>
                    </template>
                    <template
                        v-if="editedItem.type == 7"
                    >
                        <v-file-input
                            label="Загрузка изображений"
                            multiple
                            v-model="editedItem.data.value"
                            @input="valueChanged()"
                            @change="valueChanged()"
                        ></v-file-input>
                    </template>
                    <template
                        v-if="editedItem.type == 8"
                    >
                        <v-file-input
                            label="Загрузка файлов"
                            multiple
                            v-model="editedItem.data.value"
                            @change="filesLoaded(); valueChanged()"
                        >
                        </v-file-input>
                        <v-text-field
                            v-for="(v,k) in editedItem.names"
                            :key="k"
                            label="Название для файла"
                            v-model="editedItem.names[k]"
                            @input="valueChanged()"
                        >
                        </v-text-field>
                    </template>
                    <template
                        v-if="editedItem.type == 10"
                    >
                        <prism-editor
                            class="my-editor"
                            v-model="code"
                            :highlight="highlighter"
                            line-numbers
                            @input="valueChanged()"
                        />
                    </template>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="closeEdit"
                    >
                        Отмена
                    </v-btn>
                    <v-btn
                        color="blue darken-1"
                        :disabled="!canSaveBlock"
                        text
                        @click="saveItem"
                    >
                        Сохранить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
        v-model="dialogRemove"
        max-width="500px"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">Удаление блока</span>
                </v-card-title>

                <v-card-text>Вы действительно хотите удалить указанный блок?</v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="closeRemove"
                    >
                        Отмена
                    </v-btn>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="removeItem(itemToRemove)"
                    >
                        Удалить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-menu bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" class="mb-2" v-bind="attrs" v-on="on">
                    Добавить блок
                </v-btn>
            </template>
            <v-list dense>
                <v-list-item v-for="menuItem in types" :key="menuItem.id" @click="addItem(menuItem.id)">
                    <v-list-item-title>
                        <v-icon class="mr-2">{{ menuItem.icon }}</v-icon> {{ menuItem.title }}
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

        <draggable
            :list="list"
        >
            <v-card class="mt-4 pa-3 d-flex align-center" v-for="item in list" :key="item.id">
                <v-icon class="mr-2">{{ getTypeById(item.type).icon }}</v-icon>
                <span class="mr-3 limited-height">
                    <template v-if="item.type == 1 || item.type == 2 || item.type == 11">
                        {{ item.data.value }}
                    </template>
                    <div v-if="item.type == 3 || item.type == 12" v-html="item.data.value"></div>
                    <a v-if="item.type == 4" :href="item.data.value.link" target="_blank">{{ item.data.value.text }}</a>
                    <template v-if="item.type == 5 || item.type == 6">{{ item.data.value.name }}</template>
                    <template v-if="item.type == 7 || item.type == 8">{{ item.data.value.name }}</template>
                    <template v-if="item.type == 13">Форма обратной связи</template>
                </span>
                <v-btn-toggle class="ml-auto" dense>
                    <v-btn @click="editItem(item.id)" v-if="item.type != 13 && item.type != 6 && item.type != 5 && item.type != 7 && item.type != 8">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>

                    <v-btn color="error" @click="openRemoveDialog(item.id)">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </v-btn-toggle>
            </v-card>
        </draggable>
        <file-uploader ref="uploader" />
    </div>
</template>

<style lang="scss" scoped>
    .limited-height {
        max-height: 100px;
        overflow-y: hidden;
    }

    /* required class */
    .my-editor {
        /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
        background: #2d2d2d;
        color: #ccc;

        /* you must provide font-family font-size line-height. Example: */
        font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
        font-size: 14px;
        line-height: 1.5;
        padding: 5px;
    }

    /* optional class for removing the outline */
    .prism-editor__textarea:focus {
        outline: none;
    }
</style>

<script>
import Draggable from "vuedraggable";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import CKEditor from "@ckeditor/ckeditor5-vue2";
import FileUploader from "@c/FileUploader.vue";
import Editor from "@tinymce/tinymce-vue";

// import Prism Editor
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-tomorrow.css"; // import syntax highlighting styles

const TYPE_VIDEO = 6;
const TYPE_FORM = 13;

export default {
    name: "ContentEditor",
    components: {
        Draggable,
        Editor,
        FileUploader,
        PrismEditor,
    },
    props: {
        list: {
            type: Array,
            required: false,
        },
    },
    data() {
        return {
            dialogEdit: false,
            editedItem: {
                id: -1,
                type: 0,
                value: '',
                names: [],
            },
            newItemId: 0,
            code: '',
            editor: Editor,
            editorConfig: {
                language: "ru",
                baseFloatIndex: 9001,
                toolbar: [
                    "heading",
                    "bold",
                    "italic",
                    "blockQuote",
                    "link",
                    "numberedList",
                    "bulletedList",
                    "insertTable",
                ],
            },
            oldItem: {},
            dialogRemove: false,
            itemToRemove: -1,
            canSaveBlock: false,
        };
    },
    computed: {
        types: () => [
            {
                id: 1,
                icon: "mdi-format-header-1",
                title: "Заголовок",
            },
            {
                id: 2,
                icon: "mdi-format-header-2",
                title: "Подзаголовок",
            },
            {
                id: 11,
                icon: "mdi-format-header-3",
                title: "Подзаголовок 3",
            },
            {
                id: 3,
                icon: "mdi-format-letter-case",
                title: "Текст",
            },
            {
                id: 12,
                icon: "mdi-format-letter-case",
                title: "Мелкий текст",
            },
            {
                id: 4,
                icon: "mdi-link",
                title: "Ссылка",
            },
            {
                id: 5,
                icon: "mdi-image",
                title: "Изображение",
            },
            {
                id: 7,
                icon: "mdi-image-multiple",
                title: "Галерея",
            },
            {
                id: 8,
                icon: "mdi-paperclip",
                title: "Документы",
            },
            {
                id: 10,
                icon: "mdi-xml",
                title: "HTML",
            },
            {
                id: TYPE_VIDEO,
                icon: "mdi-video",
                title: "Видео",
            },
            {
                id: TYPE_FORM,
                icon: "mdi-table",
                title: "Форма обратной связи",
            },
        ],
        alignValues: () => [
            {
                id: 0,
                title: "Не задано",
            },
            {
                id: 1,
                title: "Левый край",
            },
            {
                id: 2,
                title: "Центр",
            },
            {
                id: 3,
                title: "Правый край",
            },
        ]
    },
    watch: {
        dialogEdit (val) {
            val || this.closeEdit();
        },
        dialogRemove (val) {
            val || this.closeRemove();
        },
    },
    methods: {
        valueChangedTiny(value) {
            this.canSaveBlock = (value.length > 0);
        },
        valueChanged() {
            const tp = parseInt(this.editedItem.type);
            if ((tp === 1) || (tp === 2) || (tp === 11) ) {
                this.canSaveBlock = (this.editedItem.data.value.length > 0);
            }
            if ((tp === 7) || (tp === 8) ) {
                this.canSaveBlock = (this.editedItem.data.value !== []);
            }
            if (tp === 5 || tp === TYPE_VIDEO) {
                this.canSaveBlock = (this.editedItem.data.newValue !== []);
            }
            if (tp === 4) {
                if ((this.editedItem.data.value.text !== '') &&
                    (this.editedItem.data.value.link !== '') &&
                    (this.editedItem.data.value.align > 0)
                ) {
                    this.canSaveBlock = true;
                } else {
                    this.canSaveBlock = false;
                }
            }
            if (tp === 10) {
                if (this.code.length > 0) {
                    this.canSaveBlock = true;
                } else {
                    this.canSaveBlock = false;
                }
            }
            /*
            if (tp === 8) {
                if ((this.editedItem.data.value.length > 0) && (this.editedItem.names.length > 0)) {
                    this.canSaveBlock = true;
                } else {
                    this.canSaveBlock = false;
                }
            } */
        },
        filesLoaded () {
            for(let i = 0; i < this.editedItem.data.value.length; i++) {
                this.editedItem.names.push(this.editedItem.data.value[i].name);
            }
        },
        highlighter (code) {
            return highlight(code, languages.js); //returns html
        },
        editItem (id) {
            this.canSaveBlock = false;
            for (let i = 0; i < this.list.length; i++) {
                if (this.list[i].id == id) {
                    this.oldItem = JSON.parse(JSON.stringify(this.list[i]));
                    this.editedItem = Object.assign({}, this.list[i]);
                    if (this.editedItem.type == 10) {
                        this.code = this.editedItem.data.value;
                    }
                    this.dialogEdit = true;
                    break;
                }
            }
        },
        closeEdit () {
            // this is made to restore value if editing cancelled. another way - is to "unlock" the same way editedItem from list - but it possibly lead to problem while saving data
            for (let i = 0; i < this.list.length; i++) {
                if (this.list[i].id == this.editedItem.id) {
                    this.list[i]= JSON.parse(JSON.stringify(this.oldItem));
                }
            }
            this.dialogEdit = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
                this.canSaveBlock = false;
            });
        },
        async removeItem(id) {
            for (let i = 0; i < this.list.length; i++) {
                if (this.list[i].id == id) {
                    this.list.splice(i, 1);
                    break;
                }
            }
            this.closeRemove();
        },
        addItem(type) {
            this.editedItem.id = 0;
            this.editedItem.type = type;
            switch (type) {
                case 1:
                case 2:
                case 11:
                case 3:
                case 12:
                case 10:
                case TYPE_FORM:
                    this.editedItem.data = {
                        value: "",
                    };
                    break;
                case 4:
                    this.editedItem.data = {
                        value: {
                            link: "",
                            text: "",
                            align: 0,
                        }
                    };
                    break;
                case 5:
                case TYPE_VIDEO:
                    this.editedItem.data = {
                        value: [],
                        newValue: []
                    };
                    break;
                case 7:
                case 8:
                    this.editedItem.data = {
                        value: []
                    };
                    this.editedItem.names = [];
                    break;
            }
            this.editedItem.id = 0;
            this.editedItem.type = type;

            if (type === TYPE_FORM) {
                return this.saveItem();
            }

            this.dialogEdit = true;
        },
        async saveItem() {
            if (this.editedItem.id == 0) {
                let itemData = null;

                if (this.editedItem.type == 5 || this.editedItem.type === TYPE_VIDEO) {
                    itemData = this.editedItem.data.newValue;
                } else {
                    itemData = this.editedItem.data.value;
                }

                if (this.editedItem.type == 10) {
                    itemData = this.code;
                }
                let nwitem = {
                    id: --this.newItemId,
                    type: this.editedItem.type,
                    data: { value: itemData },
                };
                if (this.editedItem.type == 8) {
                    nwitem.names = this.editedItem.names;
                }

                this.list.push(nwitem);
            } else {
                if (this.editedItem.type == 10) {
                    this.editedItem.data.value = this.code;
                    this.editedItem.value = this.code;
                }
                for (let l of this.list) {
                    if (l.id == this.editedItem.id) {
                        l.value = this.editedItem.value;
                        this.oldItem = JSON.parse(JSON.stringify(l));
                        if (this.editedItem.type == 8) {
                            l.names = this.editedItem.names;
                        }
                        break;
                    }
                }
            }
            this.dialogEdit = false;
        },
        async uploadFiles() {
            if (this.editedItem.content) {
                const copy = this.editedItem.content.map((l) => {
                    let c = Object.assign(
                        { ...l },
                        {
                            data: { ...l.data },
                        }
                    );
                    if (l.type == 7 || l.type == 8) {
                        c.data.list = c.data.list.map((l) => ({ ...l }));
                    }
                    return c;
                });
                const needUpload = [];
                for (let c of copy) {
                    if ((c.type == 5 || c.type === TYPE_VIDEO) && c.data.file) {
                        c.data.file.id = c.id;
                        needUpload.push(c.data.file);
                        delete c.data.file;
                    }
                    if (c.type == 7 || c.type == 8) {
                        for (let l of c.data.list) {
                            if (l.file) {
                                l.file.id = l.id;
                                needUpload.push(l.file);
                                delete l.file;
                            }
                        }
                    }
                }
                if (needUpload.length) {
                    const files = await this.$refs.uploader.upload(needUpload);
                    for (let c of copy) {
                        switch (c.type) {
                            case 5:
                            case TYPE_VIDEO:
                                for (let f of files) {
                                    if (f.id == c.id) {
                                        c.data = {
                                            tmp: f.tmp,
                                            name: f.name,
                                            size: Math.ceil(f.size / 1024 / 1024),
                                            type: f.type.split('/')[1]
                                        };
                                    }
                                }
                                break;
                            case 7:
                            case 8:
                                for (let l of c.data.list) {
                                    for (let f of files) {
                                        if (f.id == l.id) {
                                            l.tmp = f.tmp;
                                            l.name = f.name;
                                            l.size = Math.ceil(f.size / 1024 / 1024);
                                            l.type = f.type.split('/')[1];
                                        }
                                    }
                                }
                                break;
                        }
                    }
                }
                for (let c of copy) {
                    if (c.type == 7 || c.type == 8) {
                        c.data.list = c.data.list.map((l) => {
                            delete l.id;
                            return l;
                        });
                    }
                }
                return copy;
            }
            return false;
        },
        closeRemove () {
            this.dialogRemove = false;
            this.$nextTick(() => {
                this.itemToRemove = -1;
            });
        },
        getTypeById(id) {
            for (let t of this.types) {
                if (t.id == id) {
                    return t;
                }
            }
            return {
                id: 0,
                title: "",
                icon: "",
            };
        },
        openRemoveDialog (item) {
            this.itemToRemove = item;
            this.dialogRemove = true;
        },
    }
};
/* @input="filesLoaded(); valueChanged();" */
</script>