<template>
    <v-data-table
        :headers="headers"
        :items="list"
        class="elevation-1"
        :loading="loading"
        loading-text="Загрузка..."
        :server-items-length.sync="pagination.totalItems"
        :items-per-page="pagination.itemsPerPage"
        :options.sync="pagination"
        :footer-props="{
            'items-per-page-text':'Элементов на странице:',
            'items-per-page-all-text':'Все',
            'items-per-page-options':pagination.rowsPerPageItems
        }"
        @pagination="tablePagination"
    >
         <template v-slot:top>
            <v-dialog
            v-model="dialogEdit"
            max-width="500px"
            >
                <template v-slot:activator="{ on, attrs }">
                    <div class="admin_section_header">
                        <v-btn
                            color="primary"
                            dark
                            class="mb-2"
                            v-bind="attrs"
                            v-on="on"
                            @click="emptyData"
                        >
                            Добавить страницу
                        </v-btn>
                        <v-text-field
                            class="text-fld"
                            label="Поиск по названию"
                            v-model="searchName"
                        ></v-text-field>
                        <v-btn
                            color="primary"
                            dark
                            class="mb-2"
                            @click="doSearch"
                        >
                            Искать
                        </v-btn>
                        <v-btn
                            color="primary"
                            dark
                            class="mb-2"
                            @click="unSearch"
                        >
                            Очистить поиск
                        </v-btn>
                    </div>
                </template>

                <v-card>
                    <v-card-title>
                        <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>

                    <v-tabs v-model="curTab" background-color="transparent" color="basil" grow>
                        <v-tab>Основное</v-tab>
                        <v-tab>Контент</v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="curTab">
                        <v-tab-item>
                            <v-card color="basil" flat>
                                <v-card-text>
                                    <v-text-field
                                        label="Заголовок"
                                        v-model="editedItem.name"
                                        @change="haveEditorChanges = true"
                                    ></v-text-field>
                                </v-card-text>
                            </v-card>
							<v-card color="basil" flat>
                                <v-card-text
                                    class="text_field_with_button"
                                >
                                    <v-text-field
                                        label="Alias (URL)"
                                        v-model="editedItem.slug"
                                        class="text_field_short"
                                        @change="haveEditorChanges = true"
                                    ></v-text-field>
                                    <v-btn
                                        color="primary"
                                        dark
                                        @click="generateAlias"
                                    >
                                        Генерировать
                                    </v-btn>
                                </v-card-text>
                            </v-card>
                            <v-card color="basil" flat>
                                <v-card-text
                                    class="flex-column"
                                >
                                    <div class="text_field_with_button">
                                        <v-file-input
                                            label="Главное изображение"
                                            accept="image/jpeg, image/png"
                                            v-model="editedItem.newPreview"
                                            class="text_field_short"
                                            @change="haveEditorChanges = true"
                                        ></v-file-input>
                                        <v-btn
                                            v-show="editedItem.havePreview"
                                            color="primary"
                                            dark
                                            @click="removePreview"
                                        >
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </div>
                                    <div class="d-flex flex-column justify-space-between align-center" v-if="editedItem.id > 0">
                                        <v-img
                                            :src="$store.state.api + editedItem.preview + '?v=' + Date.now()"
                                            max-height="200"
                                            :contain="true"
                                        />
                                    </div>
                                </v-card-text>
                            </v-card>
                            <v-card color="basil" flat>
                                <v-card-text>
                                    <v-checkbox
                                        label="Скрыть страницу?"
                                        v-model="editedItem.hide"
                                        @change="haveEditorChanges = true"
                                    ></v-checkbox>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item>
                            <v-card color="basil" flat>
                                <v-card-text>
                                    <content-editor
                                        :list.sync="editedItem.content"
                                        @change="haveEditorChanges = true"
                                    ></content-editor>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="closeEdit"
                        >
                            Отмена
                        </v-btn>
                        <v-btn
                            :color="haveEditorChanges?'error darken-1':'blue darken-1'"
                            text
                            @click="save"
                        >
                            Сохранить
                        </v-btn>
                    </v-card-actions>
                </v-card>

                <file-uploader ref="uploader" />
            </v-dialog>

            <v-dialog
            v-model="dialogRemove"
            max-width="500px"
            >
                <v-card>
                    <v-card-title>
                        <span class="text-h5">Удаление страницы</span>
                    </v-card-title>

                    <v-card-text>Вы действительно хотите удалить указанную страницу?</v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="closeRemove"
                        >
                            Отмена
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="removeItem(itemToRemove)"
                        >
                            Удалить
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>

        <template v-slot:item.actions="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
                title="Изменить страницу"
            >
                mdi-pencil
            </v-icon>
            <v-icon
                small
                class="mr-2"
                @click="openRemoveDialog(item)"
                title="Удалить страницу"
            >
                mdi-delete
            </v-icon>
        </template>
        <template v-slot:item.hide="{ item }"><v-icon small v-if="item.hide">mdi-check-bold</v-icon></template>
    </v-data-table>
</template>

<script>
import FileUploader from '@c/FileUploader.vue';
import ContentEditor from '@c/ContentEditor.vue';
export default {
    name: 'Pages',
    components: {
        ContentEditor,
        FileUploader
    },
    data() {
        return {
            list: [],
            user: 0,
            headers: [
                {
                    value: 'id',
                    text: 'ID',
                    sortable: false,
                },
                {
                    value: 'name',
                    text: 'Заголовок',
                    sortable: false,
                },
                {
                    value: 'datePub',
                    text: 'Дата',
                    sortable: false,
                },
                {
                    value: 'slug',
                    text: 'Alias (URL)',
                    sortable: false,
                },
                {
                    value: 'views',
                    text: 'Просмотры',
                    sortable: false,
                },
                {
                    value: 'user_string',
                    text: 'Пользователь',
                    sortable: false,
                },
                {
                    value: 'hide',
                    text: 'Скрыть?',
                    sortable: false,
                },
                {
                    value: 'actions',
                    text: 'Действия',
                    sortable: false,
                    align: 'end',
                },
            ],
            loading: false,
            pagination: {
                page: 1,
                itemsPerPage: 10,
                totalItems: -1,
                rowsPerPageItems: [10, 20, 50, 100, -1]
            },
            hasNext: false,
            dialogEdit: false,
            editedIndex: -1,
            editedItem: {
                id: -1,
                name: '',
                slug: '',
                preview: [],
                newPreview: [],
                userId: -1,
                hide: false,
                content: [],
                removePreview: false,
                havePreview: false,
            },
            dialogRemove: false,
            itemToRemove: -1,
            curTab: null,
            haveEditorChanges: false,
            searchName: '',
        };
    },
    watch: {
        dialogEdit (val) {
            this.curTab = null;
            val || this.closeEdit();
        },
        dialogRemove (val) {
            val || this.closeRemove();
        },
    },
    mounted() {
        const url = this.$route.query;
        if (url.name !== undefined) {
            this.searchName = url.name;
        }
        this.getList(this.pagination.page);
        this.getUser();
    },
    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'Новая страница' : 'Редактирование страницы #' + this.editedItem.id;
        },
    },
    methods: {
        unSearch () {
            this.searchName = '';
            this.$router.push({ path: 'pages'});
            this.pagination.page = 1;
            this.getList(this.pagination.page);
        },
        doSearch () {
            this.$router.push({ path: 'pages', query: { name: this.searchName }});
            this.pagination.page = 1;
            this.getList(this.pagination.page);
        },
        tablePagination (data) {
            this.getList(data.page);
        },
        generateAlias () {
            if (this.editedItem.name.length > 0) {
                this.editedItem.slug = this.transliterate(this.editedItem.name);
            }
        },
        transliterate (word) {
            let answer = "";
            const accepted = 'qwertyuiopasdfghjklzxcvbnm';
            const a = {
                'ё': 'yo',
                'й': 'i',
                'ц': 'ts',
                'у': 'u',
                'к': 'k',
                'е': 'e',
                'н': 'n',
                'г': 'g',
                'ш': 'sh',
                'щ': 'sch',
                'з': 'z',
                'х': 'h',
                'ф': 'f',
                'ы': 'i',
                'в': 'v',
                'а': 'a',
                'п': 'p',
                'р': 'r',
                'о': 'o',
                'л': 'l',
                'д': 'd',
                'ж': 'zh',
                'э': 'e',
                'я': 'ya',
                'ч': 'ch',
                'с': 's',
                'м': 'm',
                'и': 'i',
                'т': 't',
                'б': 'b',
                'ю': 'yu',
                ' ': '-',
            };
            word = word.toLowerCase();
            for (let i in word) {
                if (accepted.indexOf(word[i]) > -1) {
                    answer += word[i];
                } else if (a[word[i]] !== undefined) {
                    answer += a[word[i]];
                }
            }
            return answer;
        },
        emptyData () {
            this.editedItem = {
                id: -1,
                name: '',
                slug: '',
                preview: [],
                newPreview: [],
                userId: -1,
                hide: false,
                content: [],
                removePreview: false,
                havePreview: false,
            };
        },
        removePreview () {
            this.editedItem.preview = [];
            this.editedItem.removePreview = true;
            this.editedItem.havePreview = false;
        },
        async getList(pg = 1) {
            this.loading = true;
            const on = this.pagination.itemsPerPage;
            const name = this.searchName;
            try {
                const { list, hasNext, totalCount } = await this.$api(`/admin/pages?${new URLSearchParams({
                    pg,
                    on,
                    name,
                })}`);

                list.forEach((singleNew, index) => {
                    list[index].user_string = '[' + singleNew.user[0].id + '] ' + singleNew.user[0].login;
                    list[index].slug = '/' + singleNew.slug;
                });

                this.list = list;
                this.hasNext = hasNext;
                this.pagination.page = pg;
                this.pagination.totalItems = totalCount;
            } catch (e) {
                this.$error(e.message);
            }

            this.loading = false;
        },
        async getUser() {
            try {
                let userId = await this.$api('/current');
                this.user = userId.id;
            } catch (e) {
                this.$error(e.message);
            }
        },
        async editItem (item) {
            this.editedIndex = await this.list.indexOf(item);
            
            try {
                this.curTab = null;
                let r = await this.$api('/admin/pages/' + item.id);
                this.editedItem = Object.assign({}, r);
                for (let block of this.editedItem.content) {
                    if ((block.data.value.name !== null) && (block.data.value.name !== undefined)) {
                        block.names = block.data.value.name.split(',');
                    }
                }
                this.dialogEdit = true;
                this.haveEditorChanges = false;
            } catch (e) {
                this.$error(e.message);
            }
        },
        openRemoveDialog (item) {
            this.itemToRemove = item;
            this.dialogRemove = true;
        },
        async removeItem (item) {
            try {
                let r = await this.$api('/admin/pages/' + item.id, {
                    method: 'DELETE'
                });
                if (!r.error) {
                    this.getList(this.pagination.page);
                    this.closeRemove();
                }
            } catch (e) {
                this.$error(e.message);
            }
        },
        closeRemove () {
            this.dialogRemove = false;
            this.$nextTick(() => {
                this.itemToRemove = -1;
            });
        },
        closeEdit () {
            this.dialogEdit = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        async uploadPreview() {
            if ((this.editedItem.newPreview !== null) &&
                (this.editedItem.newPreview !== undefined) &&
                (typeof this.editedItem.newPreview === 'object') &&
                (this.editedItem.newPreview.name !== undefined)) {
                const needUpload = [];

                this.editedItem.newPreview.id = this.editedItem.id;
                needUpload.push(this.editedItem.newPreview);
                delete this.editedItem.newPreview;
                const files = await this.$refs.uploader.upload(needUpload);
                for (let f of files) {
                    if (f.id == this.editedItem.id) {
                        let fileData = {
                            tmp: f.tmp,
                            name: f.name,
                            size: Math.ceil(f.size / 1024 / 1024),
                            type: f.type.split('/')[1]
                        };
                        return fileData;
                    }
                }
            }
            return false;
        },
        async uploadContent() {
            if (this.editedItem.content) {
                let uploadedFiles = [];
                for (let block of this.editedItem.content) {
                    const key = this.editedItem.content.indexOf(block);
                    if (parseInt(block.type) === 5 || parseInt(block.type) === 6) {
                        const needUpload = [];
                        if (block.data.value.size != undefined) {
                            needUpload.push(block.data.value);
                            delete block.data.value;

                            const files = await this.$refs.uploader.upload(needUpload);
                            for (let f of files) {
                                let fileData = {
                                    tmp: f.tmp,
                                    name: f.name,
                                    size: Math.ceil(f.size / 1024 / 1024),
                                    type: f.type.split('/')[1]
                                };
                                uploadedFiles[key] = fileData;
                            }
                            if (files.length > 0) {
                                return uploadedFiles;
                            }
                        }
                    }
                    if ((parseInt(block.type) === 7) || (parseInt(block.type) === 8)) {
                        if (Array.isArray(block.data.value)) {
                            const needUpload = [];
                            let num = 0;
                            for (let fitem of block.data.value) {
                                if (fitem.size != undefined) {
                                    fitem.id = num;
                                    needUpload.push(fitem);
                                    num++;
                                }
                            }
                            delete block.data.value;
                            block.data.value = [];
                            const files = await this.$refs.uploader.upload(needUpload);
                            for (let f of files) {
                                if (uploadedFiles[key] == undefined) {
                                    uploadedFiles[key] = [];
                                }
                                uploadedFiles[key].push({
                                    image: f.tmp,
                                    name: f.name,
                                    size: Math.ceil(f.size / 1024 / 1024),
                                    type: f.type.split('/')[1]
                                });
                            }
                            if (files.length > 0) {
                                return uploadedFiles;
                            }
                        } else {
                            if (parseInt(block.type) === 8) {
                                block.data.value.name = block.names.join(',');
                            }
                        }
                    }
                }
            }
            return false;
        },
        async save () {
            if (this.editedItem.name.length == 0) {
                this.$error('Название не задано. Страница не может быть сохранена.');
                return;
            }
            /*
            if (((this.editedItem.newPreview == null) || (isNaN(this.editedItem.newPreview.size))) && (this.editedItem.preview === null)) {
                this.$error('Главное изображение не задано. Страница не может быть сохранена.');
                return;
            } */

            const previewFile = await this.uploadPreview();
            const contentFiles = await this.uploadContent();
            if (contentFiles.length > 0) {
                contentFiles.forEach((block, index) => {
                    if (this.editedItem.content[index].type == 5) {
                        this.editedItem.content[index]['data'] = block;
                    }
                    if (this.editedItem.content[index].type == 6) {
                        this.editedItem.content[index]['data'] = block;
                    }
                    if (this.editedItem.content[index].type == 7) {
                        this.editedItem.content[index]['data']['list'] = block;
                    }
                    if (this.editedItem.content[index].type == 8) {
                        this.editedItem.content[index]['data']['list'] = block;
                    }
                });
            }

            if (this.editedIndex > -1) {
                try {
                    let r = await this.$api('/admin/pages/' + this.editedItem.id, {
                        method: 'PUT',
                        body: {
                            name: this.editedItem.name,
                            slug: this.editedItem.slug,
                            hide: this.editedItem.hide,
                            user_id: this.user,
                            poster: ((previewFile === false)||(previewFile === null))?'':previewFile.tmp,
                            content: this.editedItem.content,
                            removePreview: this.editedItem.removePreview
                        },
                    });
                    if (!r.error) {
                        this.getList(this.pagination.page);
                        this.closeEdit();
                    }
                } catch (e) {
                    this.$error(e.message);
                }
            } else {
                try {
                    let r = await this.$api('/admin/pages', {
                        method: 'POST',
                        body: {
                            name: this.editedItem.name,
                            slug: this.editedItem.slug,
                            hide: this.editedItem.hide,
                            user_id: this.user,
                            poster: ((previewFile === false)||(previewFile === null))?'':previewFile.tmp,
                            content: this.editedItem.content,
                            removePreview: this.editedItem.removePreview
                        },
                    });
                    if (!r.error) {
                        this.getList(this.pagination.page);
                        this.closeEdit();
                    }
                } catch (e) {
                    this.$error(e.message);
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
    .text_field_short {
        width: 50%;
    }

    .text_field_with_button {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .v-btn {
            margin: auto 0 auto 20px;
        }
    }

    .admin_section_header {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        padding: 0 0 0 20px;

        * {
            margin-right: 20px;
        }

        .text-fld {
            max-width: 25%;
            min-width: 150px;
        }
    }
</style>
